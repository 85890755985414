.animate-charcter {
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans', 'Quantify', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue',
}

h1 span::before {
  content: '';
  animation: profile 5s infinite;
  position: absolute;
}

@keyframes profile {
  0% {
    content: 'UX UI Designer'
  }

  66% {
    content: 'Frontend Developer'
  }

  100% {
    content: 'Product Designer'
  }
}