nav ul {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

nav li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: .5s;
  z-index: 4;
}

nav li:focus {
  background-color: blue;
}

.hovertext {
  position: relative;
}


.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  padding-right: 3rem;
  background-color: transparent;
  color: #000;
  text-align: center;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 0;
  right: 0;
  top: 5px
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}

.hovertext:hover li {
  background-color: #cbcbcb;
  box-shadow: 0 0 2px 2px #cbcbcb;
}

.hovertext:focus li {
  background-color: hsl(0, 0%, 97%);
}